import React from 'react';
import Container from 'components/Container/Container';
import './NewsPage.scss';

class NewsPage extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div className="NewsPage">
        <Container size="sm">{children}</Container>
      </div>
    );
  }
}

export default NewsPage;
