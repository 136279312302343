import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';

import NewsPage from 'components/NewsPage/NewsPage';
import NewsColumn from 'components/NewsColumns/NewsColumn';
import MoreButton from 'components/MoreButton/MoreButton';

import './post.scss';

export default function Post(props) {
  const { pageBlog, otherBlogs } = props.data;
  const { date, title, metaOG, metaTitle, metaDescription, path } = pageBlog.frontmatter;
  const { src } = metaOG.childImageSharp.sizes;

  return (
    <>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:image" content={src} />
        <meta property="og:url" content={src} />
      </Helmet>
      <NewsPage>
        <div className="Post-container">
          <div className="Post-content">
            <p className="Post-date">{date}</p>
            <h3>{title}</h3>
            <div className="Post-html" dangerouslySetInnerHTML={{ __html: pageBlog.html }} />
          </div>
          <NewsColumn
            title="Latest news"
            pathname={path}
            articles={otherBlogs.edges}
            type="press"
          />
        </div>
        <div className="Post-moreButton">
          <MoreButton text="News" link="/news" />
        </div>
      </NewsPage>
    </>
  );
}

export const postQuery = graphql`
  query BlogPostByPath($path: String!) {
    pageBlog: markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        date
        metaTitle
        metaDescription
        metaOG {
          childImageSharp {
            sizes(maxWidth: 660) {
              src
            }
          }
        }
      }
    }
    otherBlogs: allMarkdownRemark(
      limit: 7
      sort: { fields: [frontmatter___createdAt], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            date
            title
            path
          }
        }
      }
    }
  }
`;
